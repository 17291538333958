import { graphql, useStaticQuery } from 'gatsby'

const usePricesList3 = (): Price[] => {
  const { data } = useStaticQuery<{ data: { prices3: Price[] } }>(graphql`
  query pricesListAndpricesListAndpricesListAndPricesListAndpricesListAndpricesListAndPricesListAndpricesListAndpricesListAndPricesListAndpricesListAndpricesList {
      data: preciosYaml {
        prices3 {
          key
          text
          price
          promo
          promo_long
        }
      }
    }
  `)

  return data.prices3
}


export default usePricesList3