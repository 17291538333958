import { graphql, useStaticQuery } from 'gatsby'

const usePricesList = (): Price[] => {
  const { data } = useStaticQuery<{ data: { prices: Price[] } }>(graphql`
    query pricesList {
      data: preciosYaml {
        prices {
          key
          text
          price
          promo
          promo_long
        }
      }
    }
  `)

  return data.prices
}

export default usePricesList
