import { graphql, useStaticQuery } from 'gatsby'

const usePricesList2 = (): Price[] => {
  const { data } = useStaticQuery<{ data: { prices2: Price[] } }>(graphql`
    query pricesListAndpricesListAndpricesListAndpricesList {
      data: preciosYaml {
        prices2 {
          key
          text
          price
          promo
          promo_long
        }
      }
    }
  `)

  return data.prices2
}


export default usePricesList2